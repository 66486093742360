import React, { useEffect } from 'react';
import axios from 'axios';

const View = (props) => {
  const gameId = props.match.params.gameId;
  const uuid = props.match.params.uuid;

  useEffect(() => {
    if(uuid) {
      axios.get('/api/v1/tickets/confirm/'+uuid).then(res => {
        // console.log(res.data);
      }).catch(error => {
        alert('Error confirming order. Try again or please contact us at support@mustardsquare.com.');
      })
    }
  }, [uuid])

  if(!uuid) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{height: '100vh'}}>
        Missing order number.
      </div>
    )
  }

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col">
          <h1 className="h2">E2E</h1>
          <p>Thanks for purchasing licenses for <i>Eyeball to Eyeball</i>! You should be receiving a confirmation/receipt email shortly. Make sure to check your Spam/Junk folder if you don't see the confirmation email promptly. A member of the game team will also follow up on that email.</p>

          <div className="text-center">
            <a className="btn btn-primary" href={'/'+gameId} role="button">Done</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default View;
