import React, { useEffect, useState } from 'react';

const DebouncedButton = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  let { className, text, delay, onClick } = props;

  useEffect(() => {
    if (!isDisabled) {
      // timeout elapsed, nothing to do
      return;
    }

    // isDisabled was changed to true, set back to false after `delay`
    const handle = setTimeout(() => {
      setDisabled(false);
    }, delay);
    return () => clearTimeout(handle);
  }, [isDisabled, delay]);

  // const handleClick = (e) => {
  //   if (isDisabled) {
  //     return;
  //   }
  //
  //   setDisabled(true);
  //   return onClick(e);
  // };

  return (
    <button
      className={className}
      disabled={isDisabled}
      onClick={() => {
        onClick();
        setDisabled(true);
      }}
    >{text}</button>
  );
};

export default DebouncedButton;
