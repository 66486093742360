import React, { useEffect, useRef, useState } from 'react';

import FlipCard from "./FlipCard";

const View = (props) => {
  const [solved, setSolved] = useState([]);
  const [reset, setReset] = useState([]);
  const [endType, setEndType] = useState();
  const [endValue, setEndValue] = useState();
  const [items, setItems] = useState([]);
  const [cardBack, setCardBack] = useState();

  const memoryRef = useRef([]);

  useEffect(() => {
    if(props.game?.cardBack) setCardBack(props.game?.cardBack);

    if(props.game?.cards?.length > 0) {

      // create the cards objects (duplicate so there are matches)
      let images = [];
      props.game.cards.forEach((img, idx) => {
        images.push({ id: String(idx+1), value: img});
        images.push({ id: String(idx+1), value: img});
      });

      images = shuffleArray(images);
      setItems(images);

      // and the final message or image
      setEndType(props.game.endType);
      setEndValue(props.game.endValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fromChild = (id) => {
    if(memoryRef.current.length < 2)
      memoryRef.current.push(id);

    if(memoryRef.current.length===2) {
      if(memoryRef.current.every( (val, i, arr) => val === arr[0] )) {
        let matchedId = [...new Set(memoryRef.current)];
        let newSolved = [...solved];
        newSolved.push(matchedId[0]);
        setSolved(newSolved);
        memoryRef.current = [];

      } else {
        setTimeout(() => {
          setReset(memoryRef.current);
          memoryRef.current = [];
        }, 1000)
      }
    }
  }

  return (
    <div className="mb-5">
      {/* video could probably go here? */}

      {props.game?.cards?.length === solved.length && endType==='image' && (
        <img src={endValue} className="img-fluid" alt={endValue} />
      )}

      {props.game?.cards?.length === solved.length && endType==='text' && (
        <p>{endValue}</p>
      )}

      { props.game?.cards?.length > solved.length && (
        <p>Tap the cards to flip and see the image. Find all matches to solve the puzzle.</p>
      )}

      { props.game?.cards?.length !== solved.length && (
        <div className="clearfix">
          { items.map((img, idx) => {
            return (
              <div key={`memory-${idx}`} className="memory-item">
                <FlipCard solved={solved} reset={reset} toParent={fromChild} id={img.id} front={img.value} back={cardBack} />
              </div>
            )
          })}
        </div>
      )}
    </div>
  );
}

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default View;
