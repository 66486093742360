import React, { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { nanoid } from 'nanoid'
import { useForm } from 'react-hook-form'
// import axios from 'axios'

import DebouncedBtn from "../components/DebouncedBtn";

// proctors will either have a gameId or locationId
// check if they have a locationId, then check if location is currently in a gameId
// otherwise check the props for a gameId
// if neither exist, show error message

// FOR FRIDAY:
// 1) Display roles as checkboxes
// 2) check/uncheck all of them -- may need to do an "optional" flag in the db
//    in case we don't want to show it for things like KAL/Grenada?
//    OR MAYBE you just make them as hidden
// 3) Save on the server as ACTIVE roles
// 4) When you update the comms check, confirm that "forwardedFrom" either does/does not exist in the gameObj
// that will affect visiblity and should work great...


// you are simplifying the objects:
// gameObj
// locationObj (maybe will draw the sims they can pick?)
// roleObj (in player only)



const View = (props) => {
  const socket = useRef(null);
  const gameId = useRef(null);
  const locationId = useRef(props.match.params.locationId);
  const [simId, setSimId] = useState(null);
  const [simRoles, setSimRoles] = useState([]);
  const [simGroups, setSimGroups] = useState([]);
  const [gameObj, setGameObj] = useState(null);
  const [gameTime, setGameTime] = useState(null);
  const [gameFlags, setGameFlags] = useState([]);
  const gameFlagRef = useRef([]);

  const { register, getValues } = useForm();

  // initiate socket
  useEffect(() => {
    if(!socket.current) socket.current = io.connect();

    socket.current.on('connect', () => {
      console.log('Connected to server')
      socket.current.emit('join', {roomId: locationId.current, deviceType: 'proctor', deviceId: 'Proctor' });
    })

    socket.current.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
      setInterval(() => {
        let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (connection) window.location.reload(false);
      }, (1000 * 60 * 5));
    });

    socket.current.on('sysmsg', data => {
      console.log(data)
      if(data.type === 'refresh') window.location.reload(false);
      if(data.type === 'simId') {
        if(data.value!=='clear')
          setSimId(data.value)
        else
          setSimId(null)
      }
      if(data.type === 'gameObj') {
        setGameObj(data.value)
        if(data.value && 'id' in data.value) gameId.current = data.value.id;
        if(data.value && 'flags' in data.value) {
          setGameFlags(data.value.flags);
          gameFlagRef.current = data.value.flags;
        }

        // if gameObj is null, reset values
        if(!data.value) {
          setGameFlags([]);
          gameFlagRef.current = [];
        }
      }
      if(data.type === 'timer') setGameTime(data.value)

      if(data.type === 'flag') {
        let flag = data.value;
        // check if this flag exists in the state; if it does, remove + replace
        let found = gameFlagRef.current.findIndex(obj => obj.actionId === flag.actionId && obj.roleId === flag.roleId );
        if(found >= 0) gameFlagRef.current.splice(found, 1);
        gameFlagRef.current.push(flag);
        setGameFlags(gameFlagRef.current);
      }
    });

    return () => {
      console.log('unmounting')
      socket.current.disconnect();
      socket.current = null;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get cast for the available simulations
  useEffect(() => {
    if(locationId) {
      axios.post('/api/v1/roles/location', { locationId: locationId.current }).then(res => {
        if(res.data.roles) setSimRoles(res.data.roles);
        if(res.data.groups) setSimGroups(res.data.groups);
      }).catch(error => {
        console.log(error)
      })
    }
  }, [locationId]);

  const sendCmd = (cmd, simId, startTime) => {
    console.log('new game control: ',cmd);
    if(!startTime) startTime = 0;

    // should add a check here to check if we are currently in a game?
    // OR, will that just be handled by the gameObj sent by conductor?
    if(cmd==='start') {
      let { playSpeed, inactiveRoles } = getValues();

      // create new one
      gameId.current = nanoid();
      socket.current.emit('clientmsg', { roomId: locationId.current, gameId: gameId.current, command: 'start', simId, startTime, playSpeed, inactiveRoles });
      console.log(gameId.current)
    } else {
      socket.current.emit('clientmsg', { roomId: locationId.current, gameId: gameId.current, command: cmd });
    }
  }

  // const fileFlag = (flag) => {
  //   console.log('file new flag: ',flag);
  //   socket.current.emit('clientmsg', { roomId: locationId.current, gameId: gameId.current, flag: flag });
  // }

  const cueSim = (simId) => {
    console.log('cue sim: ',simId);
    socket.current.emit('clientmsg', { roomId: locationId.current, simId: simId });
  }

  const displayOverride = (obj) => {
    console.log('video override: ', obj);
    socket.current.emit('clientmsg', { roomId: locationId.current, displayObj: obj });
  }

  // if the locationId doesn't exist, throw an error
  if(!locationId.current) {
    return(
      <div className="d-flex align-items-center justify-content-center" style={{height: '100vh'}}>
        Need location.
      </div>
    )
  }

  return (
    <div className="container pt-3">
      <h1>Admin</h1>
      <hr />

          { (!gameObj && locationId.current) && (
            <>
            { locationId.current==='Gq8LPlGt5Oih72X6DYTKD' && (
              <>
                <button type="button" className="btn btn-primary me-1 mb-2" onClick={() => {
                  cueSim(2)
                }}>Cue Grenada</button>

                <button type="button" className="btn btn-primary me-1 mb-2" onClick={() => {
                  cueSim(4)
                }}>Cue KAL</button>
              </>
            )}

            { locationId.current==='us6Q03Pji7vVSaOT3KLgy' && (
              <button type="button" className="btn btn-primary me-1 mb-2" onClick={() => {
                cueSim(11)
              }}>Cue E2E</button>
            )}

            { locationId.current==='IQw4rPHeAPg4hBjHHenDf' && (
              <button type="button" className="btn btn-primary me-1 mb-2" onClick={() => {
                cueSim(10)
              }}>Cue Fall of Athens</button>
            )}


            <button type="button" className="btn btn-primary mb-2" onClick={() => {
              cueSim('clear')
            }}>Clear cue</button>

            { simId && (
              <>
              <hr />

              <div className="row">
                <div className="col-sm-3">
                  {simId === 2 && (
                    <DebouncedBtn className="btn btn-primary me-2 mb-2" delay={1000} text="Start Grenada" onClick={() => sendCmd('start', 2) } />
                  )}

                  {simId === 4 && (
                    <DebouncedBtn className="btn btn-primary me-2 mb-2" delay={1000} text="Start KAL" onClick={() => sendCmd('start', 4) } />
                  )}

                  {simId === 10 && (
                    <DebouncedBtn className="btn btn-primary me-2 mb-2" delay={1000} text="Start Fall of Athens" onClick={() => sendCmd('start', 10) } />
                  )}

                  {simId === 11 && (
                    <DebouncedBtn className="btn btn-primary me-2 mb-2" delay={1000} text="Start Eyeball to Eyeball" onClick={() => sendCmd('start', 11) } />
                  )}

                  <div className="form-group">
                    <select className="form-select" {...register("playSpeed")}>
                      <option value="1">1x</option>
                      <option value="2">2x</option>
                      <option value="3">3x</option>
                      <option value="4">4x</option>
                      <option value="5">5x</option>
                      <option value="6">6x</option>
                      <option value="7">7x</option>
                      <option value="8">8x</option>
                      <option value="9">9x</option>
                      <option value="10">10x</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-9">
                  {(simRoles && simRoles.filter(obj => obj.roleOptional).length > 0) && (
                    <>
                    <div>
                      <h4>Inactive Roles</h4>
                      <p>Check the boxes below if the role is <b className="text-danger">inactive/not played</b> in this game.</p>
                    </div>

                    <div style={{ columns: '2 auto'}}>
                      { simId!==10 && simRoles && simRoles.filter(obj => obj.simulationID === simId && obj.roleOptional).map((role,idx) => {
                        // if role is not optional, show toggle
                        return (
                          <div key={ 'role' + idx.toString() } className="form-check">
                            <input className="form-check-input" type="checkbox" id={'roleId-'+role.id} value={role.id} {...register("inactiveRoles")} />
                            <label className="form-check-label" htmlFor={'roleId-'+role.id}>
                              {role.roleName}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                    </>
                  )}

                  {simId===10 && simRoles && (
                    <Fragment>
                      { simGroups && simGroups.filter(obj => obj.simulationID === simId && obj.epsName.includes('Tranche')).map((group,idx) => {
                        let groupRoles = simRoles.filter(x => group.recipientIDs.includes(String(x.id)));
                        let defaultChecked = group.defaultActive ? false : true;
                        if(groupRoles?.length===0) return null;
                        return (
                          <div key={group.id} className="mb-4">
                            <h6>{group.groupName}</h6>
                            <div style={{ columns: '2 auto'}}>
                              { groupRoles.map((role,idx) => {
                                return (
                                  <div key={ 'role' + idx.toString() } className="form-check">
                                    <input className="form-check-input" type="checkbox" id={'roleId-'+role.id} value={role.id} {...register("inactiveRoles")} defaultChecked={defaultChecked} />
                                    <label className="form-check-label" htmlFor={'roleId-'+role.id}>
                                      {role.epsName} - {role.roleName}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                    </Fragment>
                  )}
                </div>
              </div>


              </>
            )}
            </>
          )}

          { gameObj && (
            <button className="btn btn-primary float-end">Download Messages</button>
          )}




          { gameObj && gameObj.status && (
            <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
              sendCmd('stop')
            }}>Stop Game</button>
          )}

          { gameObj && gameObj.status === 'AG' && (
            <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
              sendCmd('pause')
            }}>Pause Game</button>
          )}

          { gameObj && gameObj.status === 'PG' && (
            <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
              sendCmd('resume')
            }}>Resume Game</button>
          )}

          { gameObj && gameObj.status === 'CP' && (
            <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
              sendCmd('continue')
            }}>Continue Game</button>
          )}

          { gameObj && gameObj.status === 'GE' && (
            <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
              sendCmd('clear')
            }}>Clear Game</button>
          )}


          {gameObj && (
            <div className="row">
              <div className="col-6">
            <p>Game ID: {gameObj.gameId}</p>
            <p>Game status: {gameObj.status}</p>
            <p>Chapter Title: {gameObj.chapters[gameObj.currentChapter].title}</p>

            {gameTime && (
              <p>Current Time: { new Date(1000 * gameTime).toISOString().substr(14, 5) }</p>
            )}

            { gameObj.runtime && (
              <p>Chapter Runtime: { new Date(gameObj.runtime * 1000).toISOString().substr(14, 5) }</p>
            )}

            {/*<p>Chapter ID: {gameObj.chapterId}</p>
            <p>Played chapters: {gameObj.playedChapters}</p>*/}
          </div>
          <div className="col-6">
            {gameFlags?.length===0 && <p className="text-muted">No game flags yet.</p>}
            <ul>
              {gameFlags && gameFlags.map((flag, index) => {
                // console.log(flag);
                let found = simRoles.find(x => x.id === flag.roleId);
                let recipient = simRoles.find(x => x.id === flag.recipientId);
                if(!found) found = { epsName: '00', roleName: 'System' };
                // console.log(recipient);
                
                return (
                  <li key={ 'flag-' + index.toString() }>
                    {found.epsName} {found.roleName} - {flag.value}
                    { recipient && ` - ${recipient.roleName}` }
                  </li>
                );
              }
              )}
            </ul>
            { simId === 10 && (
              <div className="row mt-4">
                <div className="col-6">
                  <h4>Active Roles</h4>
                  { gameObj?.activeRoles?.sort((a, b) => a - b).map(id => {
                    let found = simRoles?.find(x => x.id===id);
                    return (<li key={`active-${id}`}>{id} - {found?.roleName}</li>)
                  })}
                </div>
                <div className="col-6">
                  <h4>Inactive Roles</h4>
                  { gameObj?.inactiveRoles?.sort((a, b) => a - b).map(id => {
                    let found = simRoles?.find(x => x.id===id);
                    return (<li key={`inactive-${id}`}>{id} - {found?.roleName}</li>)
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
          )}
      <hr />

        <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
          sendCmd('refresh')
        }}>Force Refresh</button>


      { locationId.current==='Gq8LPlGt5Oih72X6DYTKD' && (
        <>

        {/*
          Button override if you still need it
          <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
          fileFlag({roleId: 22, actionId: 1, value: 'CDC001A'})
        }}>Decision 1, CDC001A</button>*/}


        <hr />

        <DebouncedBtn className="btn btn-primary me-2 mb-2" delay={1000} text="Start Grenada at 7 mins" onClick={() => sendCmd('start', 2, 415) } />

        <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
          displayOverride({ id: 'AFO-01', template: 1, source: 'AFO-EPILOGUE.webm', type: 'webm', captions: 'AFO-EPILOGUE.vtt' })
        }}>Start Grenada Wrap-Up (AFO)</button>

        <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
          displayOverride({ id: 'AFO-01', default: true })
        }}>Clear Grenada Wrap-Up</button>

        <hr />

        <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
          displayOverride({ id: 'PR-01', template: 1, source: 'WU_001.mp4', type: 'mp4' })
        }}>Start KAL Wrap-Up Video (PR)</button>

        <button type="button" className="btn btn-primary d-block mb-2" onClick={() => {
          displayOverride({ id: 'PR-01', default: true })
        }}>Clear KAL Wrap-Up</button>
        </>
      )}
    </div>
  );
}

export default View;
