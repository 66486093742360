import React from 'react';
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs';

const View = (props) => {
  const socket = props.socket;
  const roleObj = props.roleObj;
  const gameObj = props.gameObj;
  const roomId = props.roomId;

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {
    let value = {
      content: data.content,
      postedBy: roleObj?.role?.roleName,
      timestamp: dayjs().format('MMM D, YYYY h:mma')
    };

    socket.emit('clientmsg', { roomId, gameId: gameObj.id, newsfeed: value });
    reset();
  }

  return (
    <div>
      <h4>Add to the conversation</h4>
        <form onSubmit={handleSubmit(onSubmit)} className="form mb-4" encType="multipart/form-data" autoComplete="off">
          <textarea {...register("content", {required: true})} className="form-control form-control-lg" disabled={gameObj?.status!=='AG'}></textarea>
          {errors.message && <p className="small text-danger mb-0">Please add something to post</p>}

          { gameObj?.status==='AG' && (
            <button type="submit" className="btn btn-primary mt-2 me-2">Submit</button>
          )}

          { gameObj?.status!=='AG' && (
            <button type="submit" className="btn btn-primary mt-2 me-2" disabled>Game Paused</button>
          )}

          <button type="button" className="btn btn-outline-secondary mt-2" onClick={() => reset()}>Clear</button>
        </form>

    </div>
  );
}

export default View;
