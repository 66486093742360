

export const sumByKey = (arr, key, value) => {
  const map = new Map();
  for(const obj of arr) {
    const currSum = map.get(obj[key]) || 0;
    map.set(obj[key], currSum + obj[value]);
  }
  const res = Array.from(map, ([k, v]) => ({[key]: k, [value]: v}));
  return res;
}

export const sumByTwoKeys = (data, key1, key2, column, onlyPositive) => {
  const res = data.reduce((acc, obj) => {
    // set quantity as 1 if obj.property doesn't exist/is null
    if(!obj.hasOwnProperty(column) || obj[column] === null)
      obj[column] = 0;
    else
      obj[column] = Number(obj[column]);

    const existingIndex = acc.findIndex(
      el => el[key1] === obj[key1] && el[key2] === obj[key2]
    )
    if (existingIndex > -1) {
      let newVal = acc[existingIndex][column] + obj[column];
      if(onlyPositive && newVal < 0) newVal = 0;
      acc[existingIndex][column] = newVal;
    } else {
      let newVal = obj[column];
      if(onlyPositive && newVal < 0) newVal = 0;

      let newObj = {};
      newObj[key1] = obj[key1];
      newObj[key2] = obj[key2];
      newObj[column] = newVal;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}