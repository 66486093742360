import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import parse from 'html-react-parser';

const View = (props) => {
  const { obj, roleId, activeRoles, socket, roomId, gameId } = props;
  const [show, setShow] = useState(false);
  const [qty, setQty] = useState(0);
  const [display, setDisplay] = useState('bg-danger');
  const [tradeTo, setTradeTo] = useState([]);

  const [recipient, setRecipient] = useState({});
  const [confirm, setConfirm] = useState(false);

  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const sendTo = watch('sendTo');
  const sendQty = watch('qty');

  const toggle = () => {
    setShow((s) => !s);
    setConfirm(false);
    reset();
  }

  // lklklk currently hardcoding that they can't give away everything
  const minimum = 0;

  // lklklk also currently hardcoding color value

  useEffect(() => {
    let total = obj?.total ? obj.total : 0;
    setQty(total);
    setDisplay(total===0 ? 'bg-danger' : 'bg-success');
  }, [obj])

  useEffect(() => {
    let tradeBtwn = obj.tradeBetween?.map(x => parseInt(x));
    let tradeTo = activeRoles.filter(x => tradeBtwn.includes(x.id) && x.id!==parseInt(roleId));
    setTradeTo(tradeTo);
  }, [obj, roleId, activeRoles])

  useEffect(() => {
    let found = activeRoles.find(x => x.id===parseInt(sendTo));
    setRecipient( found ? found : {});
  }, [sendTo])

  const onSubmit = (data) => {
    setConfirm(true);
  }

  const finalize = () => {
    let inventory = [
      { roleId: parseInt(sendTo), fromId: parseInt(roleId), inventoryId: obj.id, value: parseInt(sendQty) },
      { roleId: parseInt(roleId), toId: parseInt(sendTo), inventoryId: obj.id, value: parseInt(sendQty)*-1 },
    ]

    socket.emit('clientmsg', { roomId, gameId, inventory });
    toggle();
  }

  // if(qty===0) return null;

  return (
    <Fragment>
      <span className={`pointer badge rounded-pill me-1 mb-1 ${display}`} onClick={toggle}>{obj.title}: {obj.total}</span>

      <Modal show={show} size="sm" onHide={toggle} backdrop={true} keyboard={true} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>{obj.title}: {qty}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {obj.content && parse(obj.content)}

          { tradeTo.length > 0 && (
            <Fragment>
              <h5 className="mt-4">Gift</h5>
              { qty <= minimum && (
                <p className="small">You do not have enough of this item to gift.</p>
              )}
              { qty > minimum && (
                <form onSubmit={handleSubmit(onSubmit)} className="form" encType="multipart/form-data" autoComplete="off">
                  { !confirm && (
                    <Fragment>
                      <div className="row">
                        <div className="col-sm-8">
                          <select {...register("sendTo", {required: 'tbd'})} className="form-select">
                            <option value="">Select Recipient</option>
                            { tradeTo.map(obj => <option key={obj.id} value={obj.id}>{obj.roleName}</option>)}
                          </select>
                          {errors.sendTo && <p className="small text-danger mb-0">Please select a recipient</p>}
                        </div>
                        <div className="col-sm-4">
                          <select {...register("qty")} className="form-select">
                            { Array.from({length: qty-minimum}, (_, i) => i + 1).map(n => <option key={`qty-${n}`} value={n}>{n}</option>)}
                          </select>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary mt-2 ms-2 float-end">Confirm</button>
                      <button type="button" className="btn btn-outline-secondary mt-2 float-end" onClick={toggle}>Cancel</button>
                    </Fragment>
                  )}


                  { confirm && (
                    <Fragment>
                      <p className="text-danger">Are you sure you want to send <b>{recipient.roleName} {sendQty}</b> item of {obj.title?.toLowerCase()}?</p>
                      <button type="button" className="btn btn-primary mt-2 ms-2 float-end" onClick={finalize}>Yes, I'm sure</button>
                      <button type="button" className="btn btn-outline-secondary mt-2 float-end" onClick={() => setConfirm(false)}>Cancel</button>
                    </Fragment>
                  ) }
                </form>
              )}
            </Fragment>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default View;
