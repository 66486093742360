import React, { useState } from 'react';
import parse from 'html-react-parser';

const View = (props) => {
  const debug = false;
  const item = props.item;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed)
    if(item.read !== 'Y')
      toParent({type: 'comm', id: item.id});
  };

  // this sends item/trigger back up to parent script
  const toParent = (item) => {
    props.fromChild(item);
    // to use example:
    // toParent('yoooooo');
  }

  // will need to count the new ones as they appear
  // and push up to the parent...?

  return (
    <>
        <p className={`${item.read==='Y' ? 'comm-read' : ''} comm-header p-2 mb-0 mt-3 pointer no-select`} onClick={handleCollapse}>
          { debug && (<strong>{item.id} - {item.title} - {item.startTime} - {item.endTime} - {item.flagType} - {item.flag}</strong>)}
          { !debug && (<strong>{item.title}</strong>)}
        </p>
        <div className={`${!isCollapsed ? 'show' : ''} collapse`} id={ 'comm' + item.id }>
          <div className="border p-2" style={{backgroundColor: '#f8f8f8'}}>
            {parse(item.content)}
          </div>
        </div>
    </>
  );
}

export default View;
