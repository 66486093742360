import React, { useCallback, useState } from 'react';
// import { Collapse } from 'react-collapse';
import SmoothCollapse from 'react-smooth-collapse';
import parse from 'html-react-parser';

const View = (props) => {
  const material = props.material;
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback(
    () => setIsOpen(!isOpen),
    [isOpen]
  );

  return (
    <div>
      <p className="mb-0 pointer" onClick={onClick}>
        <strong>{material.title}</strong>
      </p>
      <SmoothCollapse expanded={isOpen}>
        <div className="pt-3">
          {parse(material.content)}
        </div>
      </SmoothCollapse>
      <hr />
    </div>

  );
}

export default View;
