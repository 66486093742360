import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';

const View = (props) => {
  const { obj } = props;
  const [display, setDisplay] = useState('bg-warning text-dark');
  const [show, setShow] = useState(false);
  const toggle = () => setShow((s) => !s);

  useEffect(() => {
    if(obj?.tagColor)
      setDisplay(obj.tagColor);
    else
      setDisplay('bg-warning text-dark');
  }, [obj])

  return (
    <Fragment>
      <span className={`pointer badge rounded-pill me-1 mb-1 ${display}`} onClick={toggle}>{obj.name}</span>

      <Modal show={show} size="sm" onHide={toggle} backdrop={true} keyboard={true} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>{obj.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {obj.content && parse(obj.content)}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default View;
