import React, { useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip';

const View = (props) => {
  const [isFlipped, setFlipped] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();

    if(!props.solved.includes(props.id)) {
      setFlipped((s) => !s);
      props.toParent(props.id);
    }
  }

  useEffect(() => {
    if(props.reset.includes(props.id))
      setFlipped(false);

  }, [props.id, props.reset])

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div onClick={handleClick} className="memory-item border">
        <img src={props.back} className="img-fluid" alt="Card Back" />
      </div>

      <div onClick={handleClick} className="memory-item">
        <img src={props.front} className="img-fluid" alt="Card Front" />
      </div>
    </ReactCardFlip>
  );
}

export default View;
