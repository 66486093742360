import React from 'react';
const View = (props) => {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{height: '100vh'}}>
      expsim
    </div>
  );
}

export default View;
