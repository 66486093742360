import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import {nanoid} from 'nanoid';

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, {
  stripeAccount: 'acct_1JX7DYAtld4Dyjim'
});

const View = (props) => {
  const gameId = props.match.params.gameId;
  const [uuid, setUuid] = useState();
  const [checking, setChecking] = useState(false);
  const [codeGood, setCodeGood] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();

  useEffect(() => {
    let newUuid = nanoid();
    setUuid(newUuid);
  }, [])

  const checkDiscount = (e) => {
    if(!e.target.value || e.target.value==='') {
      setChecking(false);
      setCodeGood(false);
      return;
    }

    setChecking(true);
    let code = getValues('code');
    axios.post('/api/v1/tickets/discount', {code}).then(res => {
      if(res.status===200) {
        (res.data?.status==='success') ? setCodeGood('success') : setCodeGood('fail');
      } else {
        alert('Error registering. Try again or please contact us at support@mustardsquare.com.');
      }
      setChecking(false);
    }).catch(error => {
      alert('Error checking code. Try again or please contact us at support@mustardsquare.com.');
      setChecking(false);
    })
  }

  const onSubmit = (data) => {
    setSubmitting(true);
    data.uuid = uuid;
    data.emailSent = 'N';

    axios.post('/api/v1/tickets/register', data).then(res => {
      if(res.status===200)
        checkout();
      else {
        alert('Error registering. Try again or please contact us at support@mustardsquare.com.');
        setSubmitting(false);
      }
    }).catch(error => {
      alert('Error registering. Try again or please contact us at support@mustardsquare.com.');
      setSubmitting(false);
    })

  }

  const checkout = async () => {
    const stripe = await stripePromise;
    let code = getValues('code');
    let email = getValues('emailAddress');
    let qty = getValues('qty');
    if(!qty) qty = 1;

    const response = await fetch('/api/v1/tickets/checkout', {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        uuid,
        gameId,
        ticketName: 'E2E Simulation',
        qty,
        email,
        code
       })
    });

    const session = await response.json();
    if(session?.code==='complete') {
      window.location.href = '/'+gameId+'/complete/'+uuid;
    } else {
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    }
  }

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col">
          <h1 className="h2">E2E</h1>
          <p>Thanks for your interest in <i>Eyeball to Eyeball, 1962: The Cuban Missile Crisis</i>! This website allows you to buy licenses for your class to use the Experiential Simulation platform as part of the game. We strive to keep costs as low as possible; your fees allow us to:</p>
          <ul className="ps-3">
            <li>Host the game on a server that allows for multiple sessions to be run at the same time; this eliminates a previous need to deconflict game sessions between schools</li>
            <li>Provide a small payment to the authors and coders who made this game possible</li>
          </ul>

        <p>The preferred means of payment is a single purchase of licenses for one class session. To do that, simply choose the number of users, fill out your contact info, and provide a descriptive name for your game session (a great technique is to use your course number, the semester, and any section information, e.g., HIST34522-1001).</p>
        <p>Then fill out the payment info, making sure to include a discount code if you have one. Once you submit the request, you will receive a confirmation email receipt and a member of the game team will reach out within 24 hours to coordinate next steps.</p>
        <p>If you have questions or concerns about the process of license purchasing for this game, please use the <a href="https://42ed.games/contact-us/" target="_blank" rel="noreferrer">42 Educational Games Contact Us</a> page so we can help you out!</p>

          <form onSubmit={handleSubmit(onSubmit)} className="form mb-4" encType="multipart/form-data" autoComplete="off">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group mb-3">
                  <label htmlFor="firstName" className="small text-muted">First Name</label>
                  <input type="text"  {...register("firstName", {required: 'Please enter your first name'})} className="form-control" placeholder="Enter your first name" />
                  {errors.firstName && <p className="small text-danger mt-1">{errors.firstName.message}</p>}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="lastName" className="small text-muted">Last Name</label>
                  <input type="text"  {...register("lastName", {required: 'Please enter your last name'})} className="form-control" placeholder="Enter your last name" />
                  {errors.lastName && <p className="small text-danger mt-1">{errors.lastName.message}</p>}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="emailAddress" className="small text-muted">Email Address</label>
                  <input type="email" className="form-control"
                    placeholder="Enter your email address"
                    {...register("emailAddress", {
                      required: 'Please enter your email address',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Please enter a valid email address'
                      }
                    })}
                  />
                {errors.emailAddress && <p className="small text-danger mt-1">{errors.emailAddress.message}</p>}
                </div>

              </div>
              <div className="col-sm-6">
                <div className="form-group mb-3">
                  <label htmlFor="gameName" className="small text-muted">Game Name/Description</label>
                  <input type="text"  {...register("gameName", {required: 'Please enter a name for your game'})} className="form-control" placeholder="Enter game name, e.g. HIST34522-1001" />
                  {errors.gameName && <p className="small text-danger mt-1">{errors.gameName.message}</p>}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="qty" className="small text-muted">Number of Tickets</label>
                  <input type="number" min="1" {...register("qty", {required: 'Please enter number of tickets'})} className="form-control" placeholder="Number of tickets" defaultValue="1" />
                  {errors.qty && <p className="small text-danger mt-1">{errors.qty.message}</p>}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="discount" className="small text-muted">Optional: Discount Code</label>
                  <div className="input-group mb-3">
                    { checking && (
                      <span className="input-group-text">
                        <div className="spinner-border spinner-border-sm text-secondary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </span>
                    )}
                    { codeGood==='success' && (
                      <span className="input-group-text bg-success text-white">
                        &#10004;
                      </span>
                    )}

                    { codeGood==='fail' && (
                      <span className="input-group-text bg-warning text-white">
                      	&#10060;
                      </span>
                    )}

                    <input type="search" min="1" {...register('code')} className="form-control" placeholder="Enter code" onBlur={checkDiscount} />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary mt-2 me-2" disabled={submitting}>
                {submitting && (<>Please wait...</>)}
                {!submitting && (<>Continue</>)}
              </button>
              <button type="button" className="btn btn-outline-secondary mt-2" onClick={() => reset()}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default View;
